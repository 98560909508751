import React from 'react';
import ImageContentLink from '../../../core/Components/ImageContentLink';
import Link from '../../../core/Components/Link';
import { DefaultProps, PropTypes } from '../../../core/Utils/SitecoreFields';

const CalloutCard = ({ fields }) => {
  const content = <ImageContentLink fields={fields} headingTagType="p" />;

  const gradientColour = fields.isSecondary.value
    ? 'purple-gradient'
    : 'yellow-gradient';
  const hasLink = !!fields.link.value.href && !fields.contactUsItem;
  return hasLink ? (
    <Link
      field={fields.link}
      title={fields.link.value.title || fields.title.value}
      className={`callout-card ${gradientColour}`}
    >
      {content}
    </Link>
  ) : (
    <div className={`callout-card ${gradientColour}`}>{content}</div>
  );
};

CalloutCard.propTypes = PropTypes.SitecoreItem.inject({
  ...PropTypes.ImageContentLink,
  isSecondary: PropTypes.Checkbox,
});

CalloutCard.defaultProps = DefaultProps.SitecoreItem.inject({
  ...DefaultProps.ImageContentLink,
  isSecondary: DefaultProps.Checkbox,
});

export default CalloutCard;
